.map{
    width: 1200px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:80px  auto  0 auto;;
    &-content{
       width: 1200px;
    height: 300px;
    }
}
@media screen and (max-width: 480px) {
    #allmap{
      width: 344px !important;
     height: 120px !important;  
    }
  .map{
    width: 344px !important;
    height: 120px !important; 
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:20px  auto  0 auto !important;
    &-content{
       width: 344px !important;
        height: 120px !important; 
       margin: 0 auto;
    }
}  
}