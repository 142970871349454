@media screen and (min-width: 760px) {
.navMobile{
 display: none;
}
}
@media screen and (max-width: 480px) {
.navMobile{
    width:100% !important;
    .navMobile-header-box{
        width: 100%;
         height: 56px;
          position: fixed;
          top:0;
    //    padding: 0 20px; 
        background: #fff;
        z-index: 99999 !important;
    }
   .navMobile-header{
      width:100% ;
       background: #fff;
      height: 56px;
      display: flex ;
      align-items: center;
     justify-content: space-between;
      &-icon{
       width: 86px;
        height: 22px;
        margin-left: 20px;
       img{
        width: 86px;
        height: 22px;
       }
   }   
   }
   &-content{
       width:100% !important;
        height: 250px;
        position: fixed;
       top:56px;
       bottom: 0;
       left: 0;
       right: 0;
       z-index: 999;
       overflow-y: auto !important;
       border-bottom:1px solid #eee; 
       &-wrapper{
          z-index: 999;
          background: #444;
          width:100% !important;
          background: #fff;
        height: 250px;
           overflow-y: auto !important;
       }
       &-item{
           display: block;
       }
       .tabMenu__btn{
           display: block;
           width: 140px;
           height: 60px;
           line-height: 60px;
           margin: 0 auto;
           color:#000;
        //    border-top:1px solid #d3d3d3;
           border-bottom: 1px solid #d3d3d3;
        //    margin-bottom: 30px;
           text-align: center;
       }
       .tabMenu__btn--active{
          display: block;
          color: #0d2270;
          font-weight: bold;
        //    border-top:1px solid #d3d3d3;
           border-bottom: 1px solid #0d2270;
        //    box-shadow: 5px 0px -5px 5px #0d2270;
           width: 140px;
           height: 60px;
           line-height: 60px;
       }
   }
   
}
.navMobile-header-close{
    width: 16px;
    height: 16px;
    margin-right: 20px;
    background: transparent url('../../img/close.svg') 50%/cover no-repeat;
}
.navMobile-header-show{
     margin-right:20px;
    width: 16px;
    height: 16px;
    background: transparent url('../../img/main.png') 50%/cover no-repeat;
}
#navMobile5{
    margin-bottom: 30px;
}
}