.case{
    width: 100%;
    &-banner{
        width: 100%;
        height: 605px;
        &-box{
            overflow: hidden;
            width: 100%;
            height: 605px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        
    }
    &-title{
        display: flex;
        height: 268px;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        &-china{
            font-size:36px;
            font-weight:100;
            color:rgba(28,28,28,1);
        }
        &-english{
            font-size:28px;
            font-weight:100;
            color:rgba(28,28,28,1);
            margin-top: 22px;
        }
    }
    &-cases{
        width: 100%;
        ul{
           li{
               width: 100%;
               .cases-img{
                   width: 100%;
                   z-index: 1;
                   position: relative;
                   left: 0;
                    line-height: 0;
                   img{
                     width: 100%;
                     height: 500px; 
                     color: #000;
                     z-index: 2; 
                     cursor: pointer;
                    transition: all 0.6s ease-in;
                    -webkit-transition: all 0.6s ease-in;
                    &:hover{
                        transform: scale(1.1);
                    }
                   }
                   .cases-tips{
                       width: 300px;
                       height: 150px;
                       color: #999;
                       z-index: 999;
                       position: absolute;
                       right:400px;
                       top:80px;
                       &-title{
                           width: 320px;
                           height: 116px;
                           line-height: 80px;
                           font-size: 52px;
                       }
                       &-brief{
                           width: 320px;
                           height: 120px;
                           line-height: 40px;
                           font-size: 19px;
                           margin-top: 60px;
                       }
                   }
               }
           }
        }
    }
}
@media screen and (max-width: 480px) {
    .case{
       width: 100%;
      &-banner{
        width: 100%;
        height: auto !important;
         margin-top: 56px !important;
        &-box{
             width: 345px !important;
             height: 112px !important;
            //  padding-bottom: 32% !important;
            border-radius: 8px;
              margin: 0 auto !important;
            //  overflow: hidden;
           img{
                width: 100%;
                height: 100%;
            }
        }
        
     } 
      &-title{
        display: flex;
        height: 40px !important;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 26px;
        &-china{
            font-size:15px !important;
            font-weight:100;
            color:rgba(28,28,28,1);
        }
        &-english{
            font-size:13px !important;
            font-weight:100;
            color:rgba(28,28,28,1);
            margin-top: 5px !important;
        }
    }
     &-cases{
        width: 100%;
        margin-top: 26px;
        ul{
           li{
               width: 100%;
                 height: 120px !important; 
               .cases-img{
                   width: 100%;
                   z-index: 1;
                   position: relative;
                   left: 0;
                    line-height: 0;
                      height: 120px !important; 
                   img{
                     width: 100%;
                     height: 120px !important; 
                     color: #000;
                     z-index: 2; 
                     cursor: pointer;
                    transition: all 0.6s ease-in;
                    -webkit-transition: all 0.6s ease-in;
                    &:hover{
                        transform: scale(1.1);
                    }
                   }
                   .cases-tips{
                       width:97px !important;
                       height: 77px !important;
                       color: #c2c2c2;
                       z-index: 999;
                       position: absolute;
                       right:60px !important;
                       top:10px !important;
                       &-title{
                           width: 97px !important;
                           height: 30px !important;
                           line-height: 16px !important;
                           font-size: 16px !important;
                       }
                       &-brief{
                          width: 97px !important;
                           height: 33px !important;
                           line-height: 14px !important;
                           font-size: 7px !important;
                           margin-top: 10px !important;
                       }
                   }
               }
           }
        }
    }
}
}
