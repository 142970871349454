.navigation{
  width: 100%;
  height: 88px;
  &-wrapper{
      width: 1200px;
      height: 88px;
      line-height: 88px;
      margin: auto;
      display: flex;
     align-items:center;
     justify-content: space-between;
    //   border: 1px solid;
      &-icon{
          width:192px;
          height:53px;
          background: transparent url('../../icons/icon.png') 50%/cover no-repeat;
      }
      &-nav{
          width: 625px;
          font-size: 20px;
          display: flex;
          justify-content: space-between;
          color: #333333;
          &-item{
              width: 119px;
              height: 54px;
              line-height: 54px;
              text-align: center;
              &:after{
               content: '';
                display: block;
                height: 3px;
                width: 0;
                background: transparent;
                transition: width .5s ease, background-color .5s ease;
              }
              &:hover:after{
                  width: 100%;
                background: #333333;
              }
          }
      }
  }
}
@media screen and (max-width: 480px) {
.navigation{
 display: none !important;
}
}