.join{
    &-banner{
        width: 100%;
        height: 606px;
        &-box{
            width: 100%;
            height: 606px;
            overflow: hidden;  
        img{
            width: 100%;
            height: 100%;
            }
        }
        
    }
    &-title{
        display: flex;
        height: 268px;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        &-china{
            font-size:36px;
            font-weight:100;
            color:rgba(28,28,28,1);
        }
        &-english{
            font-size:28px;
            font-weight:100;
            color:rgba(28,28,28,1);
            margin-top: 15px;
        }
    }
    &-slogan{
        width: 100%;
        width: 918px;
        height: 180px;
        margin: 0 auto;
        text-align: center;
        &-wrapper{
            width: 100%;
            &-item1{
                margin-top: 10px;
            }
            &-item2,&-item4{
                font-weight: bold;
                margin-top: 20px;
            }
            &-item3{
                margin-top: 20px; 
            }
        }
    }
    &-posts{
        width: 100%;
        &-wrapper{
            width: 1200px;
            margin: 0 auto;
            ul{
                li{
                    width: 1200px;
                    height: 907px;
                    margin-bottom: 200px;
                    &:nth-of-type(even){
                        background: #F4F4F4;
                    }
                    .joinPost{
                        display: flex;
                        justify-content: space-between;
                        &-NO{
                            width: 150px;
                            height: 150px;
                            line-height: 150px;
                            background: #F8DF00;
                            text-align: center;
                            margin-top: 100px;
                            span{
                                width: 84px;
                                color: #fff;
                                font-size: 42px;
                                padding: 0 0 10px 0;
                                border-bottom: 4px solid #333333;
                            }
                        }
                        &-icon{
                            width: 447px;
                            height: 42px;
                            background: url('../../icons/joinIcon.png') 50%/cover no-repeat;
                            margin-top: 120px;
                            margin-right: 100px;
                        }
                    }
                    .postName{
                        display: block;
                        font-size:30px;
                        font-weight:bold;
                        color:rgba(51,51,51,1);
                        line-height:30px;
                        margin-top: 28px;
                        margin-bottom: 43px;
                    }
                    .postDetail{
                        display: flex;
                        &-img{
                            width: 378px;
                            height: 618px;
                        }
                        &-content{
                            width: 729px;
                            height: 463px;
                             margin-left: 79px;
                             &-post{
                                 &-title{
                                     font-size: 20px;
                                     font-weight: bold;
                                 }
                                 &-describe{
                                     hyphens: auto;
                                     max-width: 450px;
                                     line-height: 30px;
                                     text-align: justify;
                                    text-justify: newspaper;
                                    word-break: break-all;
                                    word-wrap: break-word;
                                    margin-top: 10px;   
                                    font-size: 14px;
                                 }
                             }
                             &-condition{
                                 margin-top: 40px;
                             }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 480px) {
  .join{
      width: 100%;
        &-banner{
                width: 100%;
                height: auto !important;
                 margin-top: 56px !important;
                &-box{
                    width: 345px !important;
                    height: 122px !important;
                    //  padding-bottom: 32% !important;
                    border-radius: 8px;
                    margin: 0 auto !important;
                    //  overflow: hidden;
                img{
                        width: 100%;
                        height: 100%;
                    }
                }
                
            }
    &-title{
        display: flex;
        height: 40px !important;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 26px;
        &-china{
            font-size:15px !important;
            font-weight:100;
            color:rgba(28,28,28,1);
        }
        &-english{
            font-size:13px !important;
            font-weight:100;
            color:rgba(28,28,28,1);
            margin-top: 5px !important;
        }
    }
    &-slogan{
        width: 100%;
         height: 142px !important;
        text-align: center;
        &-wrapper{
            width: 289px !important;
            height: 142px !important;
             margin: 0 auto !important;
             font-size: 12px !important;
            &-item1{
                margin-top: 23px !important;
                line-height: 16px !important;
            }
            &-item2,&-item4{
                 line-height: 16px !important;
                font-weight: bold;
                margin-top: 10px !important;
            }
            &-item3{
                margin-top: 10px !important;
                 line-height: 16px !important;
            }
        }
    }
    &-posts{
        width: 100%;
        &-wrapper{
            width: 345px !important;
            margin: 0 auto;
            margin-top: 50px;
            ul{
                 width: 345px !important;
                li{
                    width: 345px !important;
                    height:300px !important;
                    margin-bottom: 0px !important;
                    // border: 1px solid;
                    &:nth-of-type(even){
                        // background: #F4F4F4;
                        background: none !important;
                    }
                    &:not(:first-child){
                        margin-top: 49px !important;
                    }
                    &:last-child{
                        margin-bottom: 49px !important;
                    }
                    .joinPost{
                        display: flex;
                        justify-content: space-between;
                        &-NO{
                            width: 70px !important;
                            height: 70px !important;
                            line-height: 50px !important;
                            background: #F8DF00;
                            text-align: center;
                            margin-top: 0 !important;
                            span{
                                width: 64px;
                                color: #fff;
                                font-size: 20px !important;
                                padding: 0 0 10px 0;
                                border-bottom: 4px solid #333333;
                            }
                        }
                        &-icon{
                            width: 130px !important;
                            height: 12px !important;
                            background: url('../../icons/joinIcon.png') 50%/cover no-repeat;
                            margin-top: 6px !important;
                            margin-right: 0 !important;
                        }
                    }
                    .postName{
                        display: block;
                        font-size:16px !important;
                        font-weight:bold;
                        color:rgba(51,51,51,1);
                        line-height:16px !important;
                        margin-top: 15px !important;
                        margin-bottom: 15px !important;
                    }
                    .postDetail{
                        display: flex;
                        &-img{
                            width: 110px !important;
                            height: 180px !important;
                            img{
                              width: 110px !important;
                            height: 180px !important;  
                            }
                        }
                        &-content{
                            width: 214px !important;
                            height: 162px !important;
                             margin-left: 19px !important;
                             &-post{
                                 &-title{
                                     font-size: 12px !important;
                                     font-weight: bold;
                                 }
                                 &-describe{
                                     hyphens: auto;
                                     max-width: 214px !important;
                                     line-height: 12px !important;
                                     text-align: justify;
                                    text-justify: newspaper;
                                    word-break: break-all;
                                    word-wrap: break-word;
                                    margin-top: 5px !important;   
                                    font-size: 8px !important;
                                 }
                             }
                             &-condition{
                                 margin-top: 10px !important;
                             }
                        }
                    }
                }
            }
        }
    }
}  
}