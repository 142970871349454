.recentCases{
    width: 1200px;
    margin: 82px auto 0 auto;
    display: flex;
    flex-direction:column;
    align-items: center;

    &-our{
        color:#1C1C1C;
        font-size:27px;
    }
    &-serve{
      color:#1C1C1C;
      font-size:36px;
      margin-top: 30px;
    }
    &-solid{
        margin-top: 14px;
        width: 73px;
        border-bottom: 3px solid #F8DF00;
    }
    &-tips{
        color: #A46616;
        font-size: 20px;
        margin-top: 31px;
    }
    &-introduce{
        margin-top: 110px;
        width: 1200px;
        height: 618px;
       // background: transparent url('../../../icons/caseBrack.png') 50%/cover no-repeat;
        ul{
            display:flex;
            li{
                height: 500px;
            } 
        }
    }

    &-li-one{
            width: 700px;
            background: transparent url('../../../icons/caseOne.jpg') 50%/cover no-repeat;
            div{
              margin: 200px 10px 80px 260px;
              width: 35%;
              height: 30%;
            }
           
    }
    h2{
            color: #FFFFFF;
            font-size: 18px;
            font-family: PingFangSC;
            font-weight: 100;
    }
    h1{
            margin: 10px 5px;
            color: #FFFFFF;
            font-size: 20px;
            line-height: 25px;
            font-family: PingFangSC;
    }
    &-li-two{
            background: black;
            width: 500px;
            div{
              height: 250px;
              margin: 0px;
              width: 100%;
            }
    }
    &-li-three{
            background: blue;
            width: 500px;
            div{
              height: 250px;
              margin: 0px;
              width: 100%;
            }
    }
    &-li-two-child-one{
           background:#d8d5d5;
            div{
               padding: 60px 30px;
               height: 40%;
               h2{
                  color: #333333;
               }
               h1{
                 color: #333333;
               }
           }
    }

    &-li-two-child-two{
             background: transparent url('../../../icons/caseThree.jpg') 50%/cover no-repeat;
             div{
               padding: 60px 30px;
               width: 65%;
               height: 40%;
             }
    }
   
    &-li-three-child-two{
           background:#283547;
           div{
              padding: 60px 30px;
              height: 40%;
           }
    }

    &-li-three-child-one{
           background: transparent url('../../../icons/caseTwo.jpg') 50%/cover no-repeat;
            div{
              padding: 60px 30px;
               width: 65%;
               height: 40%;
           }
    }

  .recentCases-hoverButton1{
    &:hover{
      background: #FEE503;
      color: #fff;
      cursor:pointer;
    }
  }
  .recentCases-hoverButton2{
    &:hover{
      background: #fff;
      color: #000;
      cursor:pointer;
    }
  }
  .recentCases-hoverButton3{
    &:hover{
      background: #fff;
      color: #000;
      cursor:pointer;
    }
  }
}
@media screen and (min-width: 760px) {
.recentCases-introducem{
    display: none !important;
}
}
@media screen and (max-width: 480px) {
    .recentCases{
        width: 100%;
        margin: 25px auto 0 auto !important;
        display: flex;
        flex-direction:column;
        align-items: center;
        //    border: 1px solid;
         &-our{
          color:#1C1C1C;
          font-size:11px !important;
        }
        &-serve{
        color:#1C1C1C;
        font-size:15px !important;
        margin-top: 8px !important;
        }
        &-solid{
        margin-top: 7px !important;
        width: 28px !important;
        border-bottom: 1px solid #F8DF00 !important;
        }
         &-tips{
         width: 345px !important;
        color: #A46616;
        font-size: 12px !important;
        // margin-top: 31px;
        text-align: center;
        margin: 17px auto 0 auto !important;
         }
        &-introduce{
            display: none;
       }
       &-introducem{
           width: 100%;
           height: 380px;
           margin-top: 16px;
           &-body{
              //  width: 344px;
              //  height: 380px;
               ul{
                   width: 100%;
                   height: 120px;
                //    border: 1px solid red;
                   display: flex;
                   justify-content: space-between;
               }
               li{
                   width: 50%;
               }
           }

           h1{
              margin:  5px;
              color: #FFFFFF;
              font-size: 12px !important;
              font-family: PingFangSC;
              line-height: 15px;
           }

           h2{
              color: #FFFFFF;
              font-size: 8px !important;
              font-family: PingFangSC;
              font-weight: 100;
           }

           &-box{
               height: 140px;
               width: 100%;
               background: transparent url('../../../icons/caseOneM.jpg') 50%/cover no-repeat;
               div{
                 padding: 40px 0px 0px 240px;
                 height: 20%;
               }
           }
         
           &-li-one{
               background:#d8d5d5;
                 div{
                  padding: 25px 0px 0px 15px;
                }
           }

           &-li-two{
               background:transparent url('../../../icons/caseTwo.jpg') 50%/cover no-repeat;
               div{
                  padding: 25px 0px 0px 15px;
                }
           }

           &-li-three{
               background:transparent url('../../../icons/caseThree.jpg') 50%/cover no-repeat;
               div{
                  padding: 25px 0px 0px 15px;
                }
           }

           &-li-four{
               background:#283547;
               div{
                  padding: 25px 0px 0px 15px;
                }
           }
       }
    }
}