.ourServe{
    width: 1200px;
    margin: 82px auto 0 auto;
    display: flex;
    flex-direction:column;
    align-items: center;
    &-our{
        color:#1C1C1C;
        font-size:27px;
    }
    &-serve{
      color:#1C1C1C;
      font-size:36px;
      margin-top: 30px;
    }
    &-solid{
        margin-top: 14px;
        width: 73px;
        border-bottom: 3px solid #F8DF00;
    }
    &-tips{
        color: #A46616;
        font-size: 20px;
        margin-top: 31px;
    }
    &-icon{
        width: 1064px;
       margin-top: 77px;
        ul{
            display: flex;
            justify-content: space-between;
            li{
              width: 200px;
              height: 185px;
              border: 1px solid #CFCFCF;
              display: flex; 
              flex-direction:column;
              align-items: center; 
             justify-content: center;
             border-radius:10px;
             span{
                 color: #333333;
                 display: block;
                 margin-top: 30px;
             }
            }
        }
        img{
            width: 78px;
            height: 75px;
        // filter: grayscale(100%); 
        // opacity: 0.4; 
        transition: all 0.5s;
        }
        img:hover {
            filter: none; 
            opacity: 1; 
            transform:  rotate(360deg);
        }  
    }
}
@media screen and (min-width: 760px) {
  .ourServe-iconm{
          display: none;
        }
}
@media screen and (max-width: 480px) {
    .ourServe{
    width: 100% !important;
    margin: 40px auto 0 auto !important;
    display: flex;
    flex-direction:column;
    align-items: center;
       &-our{
        color:#1C1C1C;
       font-size:11px !important;
       }
        &-serve{
        color:#1C1C1C;
        font-size:15px !important;
        margin-top: 8px !important;
      }
       &-solid{
       margin-top: 7px !important;
        width: 28px !important;
        border-bottom: 1px solid #F8DF00 !important;
      }
       &-tips{
        width: 345px !important;
        color: #A46616;
        font-size: 12px !important;
        // margin-top: 31px;
        text-align: center;
        margin: 17px auto 0 auto !important;
      }
      &-icon{
       display: none !important;
      }
      &-iconm{
        width: 246px;
        height: 230px;
        // border:1px solid;
        margin-top: 25px;
        ul{
            width: 100%;
            height: 100%;
            li{
                width: 45%;
                height: 45%;
                border:1px solid rgba(207,207,207,1);
                border-radius:10px;
                 float: left;
                 display: flex;
                 align-items:center; 
                 justify-content: center;
                //  text-align: center;
                 flex-direction: column;
                 font-size: 14px;
                 img{
                     width: 24px;
                     height: 27px;
                 }
                 span{
                     margin-top:15px;
                 }
                 &:nth-child(even){
                     margin-left: 6%
                 }
                  &:nth-child(3), &:nth-child(4){
                      margin-top: 20px;
                  }
            }
        }
      }
}
}