.posterSlide {
  width: 100%;
  height: 607px;
  // padding-bottom:40%;
  overflow: hidden;
  &__image {
    width: 100%;
    height:  607px;
  }

  &__dots {
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;
    margin: 0;
    padding: 0;
    height: 20px;

    li {
      display: inline-block;
      list-style: none;
      width: 50px;
      height: 8px;


      &:nth-child(n + 2) {
        margin-left: 10px;
      }

      &::before {
        content: '';
        display: block;
        width: 50px;
        height: 8px;
        background-color: rgba(#fff, .6);
      }

      &.slick-active::before {
        background-color:#fff;
      }

      button {
        font-size: 0;
        line-height: 0;
        color: transparent;
       display: none;
      }
    }
  }
}
.slick-arrow{
  width: 100px;
  height: 100px;
  z-index: 999;
}
// .slick-prev{
//   background: red;
// }