
.posterSlide-wrapper{
    width: 100%;
  // height: 850px !important;
}
.posterSlide {
  width: 100%;
  // height: 850px !important;
  padding-bottom:44% !important;
  overflow: hidden;
  &__image {
     width: 100%;
    height:100% !important;
  }

  &__dots {
    position: absolute;
    width: 100%;
    bottom: 40px !important;
    text-align: center;
    margin: 0;
    padding: 0;
    height: 20px;
    z-index: 99 !important;

    li {
      display: inline-block;
      list-style: none;
       width: 80px !important;
      height: 10px !important;
      //  border:1px solid #fff;
     background: rgba(255,255,255,.5);
      &.slick-active{
           background:#fff;
          }

      &:nth-child(n + 2) {
        margin-left: 18px;
      }
      &::before {
        content: '';
        display: none !important;
      }

      &.slick-active::before {
       background:#000 !important;
      }

      button {
        font-size: 12px;
        border: none;
        line-height: 16px;
        width: 80px;
        padding: 0 !important;
        // border: 1px solid #fff;
       height: 10px !important;
       display: block !important;
       position: absolute;
       outline: none;
        background-color:transparent;
      }
    }
  }
}
@media screen and (max-width: 480px) {
   .posterSlide-wrapper{
    width: 100%;
    height: 168px !important;
    margin-top: 56px !important;
    }
    .posterSlide{
     height: 168px !important;
       &__dots {
    position: absolute;
    width: 100%;
    bottom: 10px !important;
    text-align: center;
    margin: 0;
    padding: 0;
    height: 20px;
    z-index: 99 !important;

    li {
      display: inline-block;
      list-style: none;
       width: 8px !important;
        height: 8px !important;
      border-radius: 100%;
    //   border:1px solid#fff;


      &:nth-child(n + 2) {
        margin-left: 18px;
      }
      &::before {
        content: '';
        display: block;
         width: 8px !important;
         height: 8px !important;
         border-radius: 100% !important;
        background-color: rgba(#fff, 0.3);
      }

      &.slick-active::before {
       background:rgba(51,51,51,1);
      }

      button {
        font-size: 0;
        line-height: 0;
        color: transparent;
       display: none;
       position: absolute;
      }
    }
  }
    }
    .slick-arrow, .slick-next{
      display: none !important;
    }
}