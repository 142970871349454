.footer{
    width: 100%;
    &-link{
    // max-width:1920px;
    height:278px;
    background: #333333;
        &-wrapper{
        width: 1078px;
        height:278px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-icons{
            display: flex;
            flex-direction: column;
            &-icon{
               width:201px;
               height:55px;
               background: url('../../icons/icon-t.png') 50%/cover no-repeat
            }
            &-word{
                width:220px;
                height:32px;
                font-size:13px;
                font-weight:100;
                color:rgba(255,255,255,1);
                line-height:28px;
                margin-top: 19px;
            }
        }
        &-wechat{
         width: 100px;
         height: 131px;
         &-bg{
             width: 100px;
             height: 100px;
             background:url('../../icons/wechat.png') 50%/cover no-repeat;
             border: 3px solid #fff;
         }
         &-word{
          width:100px;
          height:13px;
          font-size:13px;
          font-weight:100;
          color:#fff;
          line-height:26px;  
          text-align: center;
          margin-top: 10px;
         }
        }
        }
    }
    &-copyright{
    // height:77px;
    display: flex;
    justify-items: center;
    align-items: center;
    background: #000000;
        &-wrapper{
        width: 944px;
        // height:77px;
        line-height: 25px;
        padding: 20px 0px;
        font-size: 15px;
        margin: auto;
        text-align: center;
        color: #fff;
          img{
                   position: relative;
                   bottom: -3px;
          }
        }
    }
}



.footer-lists{
    width: 476px;
    ul{
        li{
            display: flex;
             justify-content: space-between;
             width: 120px;
             height: 179px;
             font-size:16px;
            ul{
             display: flex;
            flex-direction: column;
              li{
                a{
                 color: #858585;
                 &:hover{
                     color: #fff;
                 }
                }
              }
            }

        }
    }
}


@media screen and (max-width: 480px) {
    .footer{
        display: none !important;
    }
}