.index{
    width: 100%;
    color: #333333;
    // &-banner{
    //   width: 100%;
    //  height: auto !important;
    // }
}

.company{
    margin-top: 50px;
    width: 100%;
    height: 700px;
    background:transparent url('../../icons/consulting.jpg') 50%/cover no-repeat; 
    &-wrapper{
        width: 80%;
        margin:  auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-consulting{
            margin:100px auto;
            width: 500px;
            height: 550px;
            div{
                margin-top: 30px;
                text-align: center;
            }
            input{
                width: 400px;
                height: 40px;
                border-radius: 3px;
                font-size: 16px;
                outline:none;
                border: none;
                font-family:PingFang SC;
            }
        }
        h1{
            font-size: 35px;
            color: #333333;
            font-family:PingFang SC;
            text-align: center;
            margin-top: 30px;
        }
        textarea{
            font-size: 16px; 
            resize: none;
            border-radius: 3px;
            width: 400px;
            height: 100px;
            outline:none;
            border: none;
            font-family:PingFang SC;
        }
        button{
            background-color: #FEE503;
            border: none;
            color: #333333;
            padding: 10px 152px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-family:PingFang SC;
            font-size: 16px;
            border-radius: 3px;
            outline:none;
        }
    } 
}

@media screen and (max-width: 480px) {
.company{
    margin-top: 50px;
    width: 100%;
    height: 300px;
    background:transparent url('../../icons/consultingM.jpg') 50%/cover no-repeat; 
    &-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 80%;
        margin: auto;
        &-consulting{
            margin:auto;
            width: 100%;
            div{
                margin-top: 15px;
                text-align: center;
            }
            input{
                width: 220px;
                height: 18px;
                border-radius: 3px;
                font-size: 11px;
                outline:none;
            }
        }
        h1{
            font-size: 18px;
            color: #333333;
            font-family:PingFang SC;
            text-align: center;
            margin-top: 40px;
        }
        textarea{
            font-size: 11px; 
            resize: none;
            border-radius: 3px;
            font-family:PingFang SC;
            height: 50px;
            width: 220px;
            outline:none;
        }
        button{
            background-color: #FEE503;
            border: none;
            color: #333333;
            padding: 5px 75px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-family:PingFang SC;
            font-size: 11px;
            border-radius: 3px;
            outline:none;
        }
    } 
}
}