.contact{
    width: 100%;
    &-banner{
        width: 100%;
        height:606px;
        &-box{
         width: 100%;
        height:606px;
        overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                }
        }
        
    }
        &-title{
        display: flex;
        height: 268px;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        &-china{
            font-size:36px;
            font-weight:100;
            color:rgba(28,28,28,1);
        }
        &-english{
            font-size:28px;
            font-weight:100;
            color:rgba(28,28,28,1);
            margin-top: 22px;
        }
    }
    &-info{
        width: 1200px;
        height:778px;
        background: url('../../icons/contactInBG.jpg') 50%/cover no-repeat;
        margin: 0 auto;
        display: flex;
        align-items: center;
        z-index: 1;
        &-icons{
         color:#fff;
         z-index: 999;
         margin: 0 auto;
          display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
         ul{
             display: flex;
              width: 920px;
              justify-content: space-between;
             li{
                 width: 171px;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                .contactIcons{
                    width: 150px;
                    height: 150px;
                    background: transparent url('../../icons/border.png') 50%/cover no-repeat;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    &-img{
                        width: 80px;
                        height:80px;
                        -webkit-transition: -webkit-transform 2s ease-out;
                        -moz-transition: -moz-transform 2s ease-out;
                        -o-transition: -o-transform 2s ease-out;
                        -ms-transition: -ms-transform 2s ease-out;
                        &:hover{
                            -webkit-transform: rotateZ(360deg);
                            -moz-transform: rotateZ(360deg);
                            -o-transform: rotateZ(360deg);
                            -ms-transform: rotateZ(360deg);
                            transform: rotateZ(360deg);
                        }
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .contactTitle{
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    margin-top: 20px;
                  &-name{
                      margin-top: 15px;
                      line-height: 25px;
                      text-align: center;
                  }
                }
             }
         }
        
        }
    }
}
@media screen and (max-width: 480px) {
  .contact{
    width: 100%;
     &-banner{
        width: 100%;
        height: auto !important;
         margin-top: 56px !important;
        &-box{
             width: 345px !important;
             height: 112px !important;
            //  padding-bottom: 32% !important;
            border-radius: 8px;
              margin: 0 auto !important;
            //  overflow: hidden;
           img{
                width: 100%;
                height: 100%;
            }
        }
        
     } 
        &-title{
         display: flex;
        height: 40px !important;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 26px;
        &-china{
            font-size:15px !important;
            font-weight:100;
            color:rgba(28,28,28,1);
        }
        &-english{
            font-size:13px !important;
            font-weight:100;
            color:rgba(28,28,28,1);
            margin-top: 5px !important;
        }
    }
    &-info{
        width: 345px !important;
        height:244px !important;
        background: url('../../icons/contactInBG.jpg') 50%/cover no-repeat;
        margin: 0 auto;
        display: flex;
        align-items: center;
        z-index: 1;
        &-icons{
         color:#fff;
         z-index: 999;
         margin: 0 auto;
          display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
         ul{
             display: flex;
             width: 345px !important;
            //   justify-content: space-between;
             li{
                 width: 150px !important;
                 height: 135px !important;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                // border: 1px solid #fff;
                &:last-child{
                    .contactIcons{
                        margin-top: 10px !important;
                    }
                }
                .contactIcons{
                     width: 40px !important;
                    height:40px !important;
                    background: transparent url('../../icons/border.png') 50%/cover no-repeat;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    // border: 1px solid #fff;
                    &-img{
                     width: 34px !important;
                           height:35px !important;
                        -webkit-transition: -webkit-transform 2s ease-out;
                        -moz-transition: -moz-transform 2s ease-out;
                        -o-transition: -o-transform 2s ease-out;
                        -ms-transition: -ms-transform 2s ease-out;
                        &:hover{
                            -webkit-transform: rotateZ(360deg);
                            -moz-transform: rotateZ(360deg);
                            -o-transform: rotateZ(360deg);
                            -ms-transform: rotateZ(360deg);
                            transform: rotateZ(360deg);
                        }
                        img{
                           width: 34px !important;
                           height:35px !important;
                        }
                    }
                }
                .contactTitle{
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    margin-top: 20px;
                     font-size: 7px !important;
                    //   border: 1px solid #fff;
                  &-name{
                     width: 60px !important;
                      margin-top: 0px !important;
                      line-height: 10px !important;
                      text-align: center;
                  }
                }
             }
         }
        
        }
    }
}  
}
