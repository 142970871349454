.about{
    width: 100%;
    &-banner{
        width: 100%;
        height: 605px;
        &-box{
             width: 100%;
             height: 605px;
             overflow: hidden;
           img{
            width: 100%;
            height: 100%;
            }
        }
        
    }
    &-since{
        width: 1200px;
        display: flex;
       flex-direction: column;
       align-items: center;
       margin: 90px 0 94px 0;
       &-title{
        width:600px;
        height:62px;
        font-size:80px;
        font-weight:bold;
        color:rgba(51,51,51,0.21);
        line-height:26px;
        animation: bounceInLeft 5s 0.25s linear forwards;
         @keyframes bounceInLeft{
             from, 60%, 75%, 90%, to {
                    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);}
                    0% {opacity: 0;transform: translate3d(-3000px, 0, 0);}
                    60% {opacity: 1;transform: translate3d(25px, 0, 0);}
                    75% {transform: translate3d(-10px, 0, 0);}
                    90% {transform: translate3d(5px, 0, 0);}
                    100% {opacity: 1;transform: none;}
         }
       }
    }
    &-introduction{
        display: flex;
        flex-direction:column;
        align-items: center;
          width: 935px;
          margin: 0 auto;
        &-title{
             height: 37px;
            line-height: 37px;
            display: flex;
            justify-content: center;
        &-name{
            font-size:36px;
            color: #1C1C1C;
            width: 150px;
        }
        &-solid{
            width: 120px;
            line-height: 37px;
            height: 3px;
            background: transparent url('../../icons/solid.png') 50%/cover no-repeat;
            margin-top: 15px;
            margin-left: 10px;
        }
        }
        &-company{
            height:27px;
            font-size:28px;
            font-weight:100;
            color:rgba(28,28,28,1);
            margin-top: 20px;
        }
        &-content{
            width:935px;
            height:110px;
            font-size:20px;
            font-weight:100;
            color:rgba(51,51,51,1);
            line-height:30px;
            margin: 50px auto;
        }
        &-mind{
            width: 925px;
            height: 51px;
            display: flex;
            margin-bottom: 110px;
            ul{
                display: flex;
                width: 100%;
                li{
                display: flex;
                 width: 45%;
                  height: 51px;
                  &:nth-child(2){
                      margin-left: 10%;
                  }
                  .about-love{
                      width: 54px;
                      &-tips{
                          height: 27px;
                          font-size: 28px;
                          white-space: nowrap;
                          text-align: center;
                          color: #666666;
                      }
                      &-name{
                        height:12px;
                        font-size:14px;
                        font-family:PingFang-SC-Thin;
                        font-weight:bold;
                        color:#999999;
                        line-height:30px;
                        text-align: center;
                      }
                  }
                  .about-loveContent{
                      width: 313px;
                      height: 51px;
                      font-size: 14px;
                      margin-left: 10px;
                      line-height: 18px;
                      color: #999999;
                  }
                }
            }
        }
    }
    &-system{
        width: 100%;
        height: 717px;
        background: #F8DF00;
         display: flex;
        align-items: center;
        &-wrapper{
            width: 936px;
            height: 440px;
            background: transparent url('../../icons/aboutSystem.png') 50%/cover no-repeat;
            margin: 142px auto;
            ul{
                width: 100%;
                height: 100%;
                li{
                    width: 44%;
                    height: 50%;
                    float: left;
                    &:nth-of-type(even){
                        margin-left:12%;
                    }
                    .about-system-item{
                        width: 310px;
                        height: 68px;
                        margin: 80px auto;
                         &-title{
                          font-weight: bold;
                      }
                      &-content{
                          font-size: 14px;
                      }
                    }
                }
            }
        }
    }
    &-powerful{
        width: 100%;
        &-wrapper{
            width: 844px;
            height: 634px;
            margin: 140px auto;
            display: flex;
            &-left{
                width: 398px;
                height: 634px;
                .aboutPowerful-wrapper{
                     width: 398px;
                     height: 158px;
                    //  border: 1px solid #000;
                     &-title{
                         display: flex;
                         justify-content: flex-end;
                         margin-top: 40px;
                         margin-right: 20px;
                        font-size:20px;
                        // font-weight:bold;
                        color:rgba(51,51,51,1);
                        line-height:26px;
                     }
                     &-content{
                         width:393px;
                        height:39px;
                        font-size:14px;
                        // font-weight:bold;
                        color:rgba(153,153,153,1);
                        line-height:24px;
                     }
                }
            }
            &-middle{
               width:17px;
               height:634px;
               padding: 0 10px;
            }
            &-right{
                width: 398px;
                height: 634px;
                .aboutPowerful-wrapper{
                     width: 398px;
                     height: 158px;
                       &-title{
                         display: flex;
                         justify-content: flex-start;
                         margin-top: 40px;
                         margin-right: 20px;
                        font-size:20px;
                        // font-weight:bold;
                        color:rgba(51,51,51,1);
                        line-height:26px;
                     }
                     &-content{
                         width:393px;
                        height:39px;
                        font-size:14px;
                        // font-weight:bold;
                        color:rgba(153,153,153,1);
                        line-height:24px;
                     }
                }
            }
        }
    }  
}
@media screen and (min-width: 760px) {
.about-system-mobile{
    display: none;
}
}
@media screen and (max-width: 480px) {
 .about{
    width: 100%;
    &-banner{
        width: 100%;
        height: auto !important;
         margin-top: 56px !important;
        &-box{
             width: 345px !important;
             height: 112px !important;
            //  padding-bottom: 32% !important;
            border-radius: 8px;
              margin: 0 auto !important;
            //  overflow: hidden;
           img{
                width: 100%;
                height: 100%;
            }
        }
        
    }
    &-since{
        width:100% !important;
        display: flex;
       flex-direction: column;
       align-items: center;
       margin: 34px auto;
       &-title{
        width:345px !important;
        height:32px !important;
        font-size:20px !important;
        font-weight:bold;
        color:rgba(51,51,51,0.21);
        line-height:26px;
        animation: bounceInLeft 5s 0.25s linear forwards;
         @keyframes bounceInLeft{
             from, 60%, 75%, 90%, to {
                    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);}
                    0% {opacity: 0;transform: translate3d(-3000px, 0, 0);}
                    60% {opacity: 1;transform: translate3d(25px, 0, 0);}
                    75% {transform: translate3d(-10px, 0, 0);}
                    90% {transform: translate3d(5px, 0, 0);}
                    100% {opacity: 1;transform: none;}
         }
       }
    }
      &-introduction{
        display: flex;
        flex-direction:column;
        align-items: center;
          width: 330px !important;
          height: auto !important;
        //  border: 1px solid;
          margin: 0 auto;
        &-title{
            // width: 80px !important;
             height: 37px;
            line-height: 37px;
            display: flex;
            justify-content: center;
            white-space: nowrap !important;
        &-name{
            font-size:14px !important;
            color: #1C1C1C;
            width: 44px !important;
        }
        &-solid{
            width:31px !important;
            line-height: 37px;
            height: 1px !important;
            background: transparent url('../../icons/solid.png') 50%/cover no-repeat;
            margin-top: 18px !important;
            margin-left: 25px !important;
        }
        }
        &-company{
            height:27px;
            font-size:10px !important;
            font-weight:100;
            color:rgba(28,28,28,1);
            margin-top: 5px !important;
        }
        &-content{
         width: 330px !important;
            height:auto  !important;
            font-size:7px !important;
            font-weight:100;
            color:rgba(51,51,51,1);
            line-height:12px !important;
            margin: 10px auto 0 auto!important;
        }
        &-mind{
            width: 264px !important;
            height: 25px !important;
            display: flex;
            margin-top:15px !important;
            margin-bottom: 0 !important;
            ul{
                display: flex;
               width: 264px !important;
                li{
                display: flex;
                 width: 127px !important;
                  height:24px !important;
                  &:nth-child(2){
                      margin-left: 10%;
                  }
                  .about-love{
                      width: 16px !important;
                      margin-top: 10px;
                      &-tips{
                          height: 13px !important;
                          font-size: 10px !important;
                          white-space: nowrap;
                          text-align: center;
                          color: #666666;
                      }
                      &-name{
                        height:12px;
                        font-size:7px !important;
                        font-weight:bold;
                        color:#999999;
                        line-height:10px !important;
                        text-align: center;
                      }
                  }
                  .about-loveContent{
                      width: 150px !important;
                      height: auto !important;
                      font-size: 7px !important;
                      margin-left: 10px;
                      line-height: 10px !important;
                      color: #999999;
                  }
                }
            }
        }
    }
      &-system{
        width: 100%;
        height: 204px !important;
        background: #F8DF00;
         display: flex;
        align-items: center;
        margin-top: 60px;
        &-wrapper{
            display: none !important;
            width: 345px !important; 
            height:176px !important;
            background: transparent url('../../img/aboutSystem.png') 50%/cover no-repeat;
            margin: 0 auto!important;
            ul{
                width: 345px !important; 
                height:176px !important;
                li{
                    width:172px !important;
                    height: 88px !important;
                    float: left;
                    &:nth-of-type(even){
                        margin-left:12%;
                    }
                    .about-system-item{
                         width:172px !important;
                        height: 68px;
                        margin: 90px auto;
                         &-title{
                          font-weight: bold;
                      }
                      &-content{
                          font-size: 14px;
                      }
                    }
                }
            }
        }
         &-mobile{
        display: block;
            width: 345px !important; 
            height:176px !important;
            margin: 15px  auto 0  auto!important;
            img{
                width: 345px !important; 
                height:160px !important; 
            }
    }
    }
    &-powerful{
        width: 100%;
        margin-bottom: 40px;
        &-wrapper{
            width: 345px !important;
           height:364px !important;
            margin: 56px auto 0 auto !important;
            display: flex;
            justify-content: space-between !important;
            &-left{
                 width: 157px !important;
                 height:364px !important;
                .aboutPowerful-wrapper{
                     width: 160px !important;
                     height: 70px !important;
                    //  border: 1px solid #000;
                     &-title{
                         width: 157px !important;
                         display: flex;
                         justify-content: flex-end;
                         margin-top: 40px;
                         margin-right: 0 !important;
                        font-size:10px !important;
                        // font-weight:bold;
                        color:rgba(51,51,51,1);
                        line-height:26px;
                     }
                     &-content{
                         width: 157px !important;
                        height:19px !important;
                        font-size:7px !important;
                        // font-weight:bold;
                        color:rgb(153, 153, 153);
                        line-height:14px !important;
                     }
                }
            }
            &-middle{
               width:10px !important;
               height:384px !important;
               padding: 0 10px;
               img{
                 width:10px !important;
               height:384px !important;  
               }
            }
            &-right{
                 width: 157px !important;
               height:364px !important;
                .aboutPowerful-wrapper{
                      width: 157px !important;
                     height: 70px !important;
                       &-title{
                         width: 120px !important;
                         display: flex;
                         justify-content: flex-start !important;
                         margin-top: 40px;
                         margin-right: 0 !important;
                       font-size:10px !important;
                        // font-weight:bold;
                        color:rgba(51,51,51,1);
                        line-height:26px;
                     }
                     &-content{
                         width: 157px !important;
                        height:19px !important;
                        font-size:7px !important;
                        // font-weight:bold;
                        color:rgb(153, 153, 153);
                        line-height:14px !important;
                     }
                }
            }
        }
    }  
}   
}