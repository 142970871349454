.customized{
    width: 100%;
    height: 170px;
    // background: url('../../icons/yellowBG.png') 50%/cover no-repeat;
    background: #FEE503;
    &-wrapper{
        width: 1200px;
       height: 170px;
        margin: 0 auto;
         display: flex;
         align-items: center;
         justify-content: space-between;
        &-title{
           width: 450px;
           height: 60px;
           &-big{
            width:300px;
            height:28px;
            font-size:24px;
            // font-weight:bold;
            color:rgba(51,51,51,1);
            line-height:32px;
           }
           &-small{
               width:450px;
                height:18px;
                font-size:18px;
                font-family:PingFang-SC-Regular;
                // font-weight:bold;
                color:rgba(51,51,51,1);
                line-height:32px;
                margin-top: 12px;
           }
        }
        &-button{
            width: 143px;
            height: 40px;
            line-height: 41px;
            background: #1C1C1C;
            border-radius: 10px;
            text-align: center;
            color: #fff;
            font-size: 20px;
            &:hover{
                background: #fff;
                color: #000;
            }
        }
    }
}
@media screen and (max-width: 480px) {
   .customized{
    width: 100%;
    height:52px  !important;
    background: url('../../icons/yellowBG.png') 50%/cover no-repeat;
    &-wrapper{
        width: 327px !important;
        height:52px  !important;
        margin: 0 auto;
         display: flex;
         align-items: center;
         justify-content: space-between;
        &-title{
           width: 327px !important;
          height:52px  !important;
          line-height: 52px;
           &-big{
            width:130px !important;
            height:10px !important;
            font-size:14px !important;
            font-weight: bold;
            // font-weight:bold;
            color:rgba(51,51,51,1);
            line-height:32px;
            margin-top: 5px;
           }
           &-small{
              width: 327px !important;
                height:18px;
                font-size:7px !important;
                // font-weight:bold;
                color:rgba(51,51,51,1);
                line-height:32px;
                margin-top: 7px !important; 
           }
        }
        &-button{
            display: none !important;
            width:100px ;
            font-size:10px ;
            font-weight:100;
            color:#fff;
            line-height:25px;
            background: #333;
            text-align: center;
            padding: 10px 10px;
            border-radius: 8px;
            margin-right: 180px;
            &:hover{
                background: #fff;
                color: #000;
            }
        }
    }
} 
}