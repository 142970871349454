@media screen and (min-width: 760px) {
  .footerMobile{
    display: none!important;
  }
}
@media screen and (max-width: 480px) {
    .footerMobile{
        display: block;
        width: 100%;
        background: #4B4B4B;
        color: #fff;
        &-box{
           width: 312px;
           height: 435px;
           margin: 0 auto;
           padding-top: 40px;
        }
        .footerMobile-home{
            width: 312px;
            height: auto;
            // border:1px solid #fff;
            &-title{
               width: 312px;
                height: 30px;
                border-bottom: 1px solid #fff; 
            }
            span{
                display: block;
                width: 101px;
                height: 30px;
                border-bottom: 1px solid #FFE839;
                z-index: 99999;
                font-size: 15px;
            }
            ul{
                width: 312px;
                display: flex;
                padding-top: 15px;
                li{
                    width: 75px;
                    height: 28px;
                    margin-right: 15px;
                    border: 1px solid #fff;
                    text-align: center;
                    line-height: 28px;
                    border-radius: 5px;
                    font-size: 13px;
                    margin-bottom: 30px;
                    a{
                 color: #fff;
                    }
                }
            }
        }
        .footer-tips{
            background: #000;
            font-size: 10px;
            width: 100%;
            &-box{
                // width: 100%;]
                padding: 10px 0;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
               .footer-tips-item2{
                    margin: 5px 0;
                }
               img{
                   position: relative;
                   bottom: -2px;
                   width: 15px;
               }
            }
        }
    }
}