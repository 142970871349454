
.detail{
    width: 65%;
    margin: auto;
   
    &-banner{
        width: 100%;
        height: 707px;
        background: pink;
        margin-bottom: 100px;
        &-content{
           margin-left:150px;
           width: 40%;
           height: 50%;
           position: relative;
           top: 250px;
           h2{
               color: white;
               font-size: 35px;
               padding-top: 20px;
               line-height:30px;
           }
           h5{
               color: yellow;
               font-size: 25px;
               line-height:50px;
           }
           p{
               color: white;
               font-size: 22px;
               line-height:30px;
           }
        }
    }
    &-silder{
        width: 100%;
        height: 500px;
        // margin: 0 auto;
        overflow: hidden;
        ul{
            width: 100%;
            li{
                width: 100%;
                height: 500px;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .detail{
         margin-top:75px; 
         width: 98%;
         &-banner{
            width: 100%;
            height:  160px !important;
            background: pink;
            margin-bottom: 50px;
            border-radius: 8px;
            &-content{
                margin-left:20px;
                width: 60%;
                height: 40%;
                position: relative;
                top: 50px;
                 h2{
                    color: white;
                    font-size: 13px !important;
                    padding-top: 2px;
                    line-height:12px;
                }
                h5{
                    color: yellow;
                    font-size: 9px !important;
                    line-height:12px;
                }
                p{
                    color: white;
                    font-size: 8px !important;
                    line-height:12px;
                }
            }
        }
    }
}